html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding:0;
  -webkit-text-size-adjust: none !important;
  background-color: #0F0F0F;
}
a{
  text-decoration: none !important;
  cursor: pointer;
}

div{
  box-sizing: border-box;
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul{
  padding-inline-start: 0px;
  margin-block-start: 0px;
}

::-webkit-scrollbar {
  /* display:none; */
  width: 2px !important;
  height: 5px !important; 
  background-color: rgba(32,32,32,0.5) !important;
}

::-webkit-scrollbar-thumb {
  background-color: #202020 !important;
  background-clip: padding-box;
  border-radius: 0;
}
::-webkit-scrollbar-thumb :hover {
  background-color: rgba(32,32,32,0.5);
}
      
/* ::-webkit-scrollbar {display:none} */

input {
  overflow: visible !important;
}

/* 选年份日期更改样式 */
.MuiPickersToolbar-toolbar{
  height: 20px !important;
  background-color:#202020 !important;
}
.MuiPickersToolbar-toolbar button:nth-child(2){
  display: none !important;
}

.MuiPickersToolbar-toolbar .MuiPickersToolbarText-toolbarTxt{
  color: #FF9900 !important;
}
.MuiPickersBasePicker-pickerView .MuiPickersCalendarHeader-iconButton .MuiIconButton-label{
  color: #fff !important;
}

.pb1{
  padding-bottom: 8px;
}
.pt1{
  padding-top: 8px;
}
.mr1{
  margin-right: 8px !important;
}
.mr3{
  margin-right: 24px  !important;;
}
.ml1{
  margin-left: 8px !important;
}

.ml2{
  margin-left: 16px !important;
}

.ml3{
  margin-left: 24px !important;
}

.ml4{
  margin-left: 32px !important;
}

.ml5{
  margin-left: 40px;
}

.mt1{
  margin-top: 8px !important;
}

.mt2{
  margin-top: 16px !important;
}
.mt3{
  margin-top: 24px !important;
}

.mb1{
  margin-bottom: 8px !important;
}
.mb2{
  margin-bottom: 16px !important;
}
.mb3{
  margin-bottom: 24px !important;
}
.mt4{
  margin-top: 32px !important;
}

.mt5{
  margin-top: 40px !important;
}

.ml3{
  margin-left: 24px;
}

.tr{
  text-align: right !important;
}

.tc{
  text-align: center !important;
}

.tl{
  text-align: left !important;
}

.MuiAutocomplete-option{
  padding-left: 0 !important
}

.react-grid-item > .react-resizable-handle{
  z-index: 100
}
